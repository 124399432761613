<template>
  <div>
    {{eventDate(event)}}
  </div>
</template>

<script>
import moment from 'moment'
import MomentTimezone from 'moment-timezone'


export default {
  props: ['event'],
  created(){
    moment.locale(current_locale)
  },
  data(){
    return {
      current_locale: current_locale,
      timezone: moment.tz.guess(true)
    }
  },
  methods: {
    eventDate(event) {
      let startDate = moment.tz(event.start, this.timezone)
      let endDate = moment.tz(event.end, this.timezone)

      let sameDay = startDate.dayOfYear() === endDate.dayOfYear()
      let sameMonth = startDate.month() === endDate.month()
      let sameYear = startDate.year() === endDate.year()

      let returnDate = ''
      if (sameYear && sameMonth && sameDay) {
        returnDate = startDate.format('LL')
      } else if (!sameYear) {
        returnDate = startDate.format('LL') + ' - ' + endDate.format('LL')
      } else if (sameYear && !sameMonth) {
        returnDate = startDate.format('MMMM D') + ' - ' + endDate.format('MMMM D, YYYY')
      } else if (sameYear && sameMonth && !sameDay) {
        returnDate = startDate.format('MMMM D') + ' - ' + endDate.format('D, YYYY')
      }
      
      return returnDate
    }
  }
}
</script>

<style>

</style>
