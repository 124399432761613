<template>
  <div v-if="events.length > 0">
    <h1 class="text-center table-title">{{kind}}</h1>
    <table class="table table-responsive-sm">
      <tr class="event-table-header">
        <th colspan="2">{{ $t("event" )}}</th>
        <th>{{ $t("event_date" )}}</th>
        <th>{{ $t("location" )}}</th>
        <th class="d-none d-lg-table-cell">{{ $t("climbers" )}}</th>
      </tr>
      <tr class="event-table-body event-link" v-for="ev in events.slice(0,30)" :key="ev.id" @click="navigateToEvent(ev.link)">
        <td>
          <img :src="ev.logo" alt="event logo" class="img-fluid event-logo">
        </td>
        <td>
          <div class="event-name">{{ev.name}}</div>
          <div class="event-competition-name">{{ev.competition.name}}</div>
        </td>
        <td>
          <event-dates :event="ev"></event-dates>
        </td>
        <td>{{ev.location}}</td>
        <td class="d-none d-lg-table-cell">{{ev.climbers}}</td>
      </tr>
    </table>
  </div>
</template>

<script>

import EventDates from '../../shared/event_dates.vue'

export default {
  components: {
    'event-dates': EventDates
  },
  props: ["kind", "events"],
  methods: {
    navigateToEvent: function(url){
      window.location.href = url;
    }
  }
}
</script>

<style scoped>
  .event-link {
    cursor: pointer;
  }

  .table-title {
    font-family: 'Intro';
    font-size: 1rem;
    padding-top: 2.5rem; 
    padding-bottom: 1rem; 
  }
  
  .event-table-header {
    background-color: #E6e4e0;
    font-size: 14px;
  }

  .event-table-body {
    background-color: white;
    font-size: 14px;
  }

  .event-logo {
    max-width: 2.5rem;
    max-height: 2.5rem;
    margin: 0.75rem 1.06rem 0.81rem 1rem;

  }

  .event-table-body > td {
    vertical-align: middle;
  }

  .event-table-body > td > a {
    color: black;
  }

  .event-table-body > td > a:hover {
    color: black;
    text-decoration: none;
  }

  .event-name {
    font-family: "Glober Bold";
  }

  .event-competition-name {
    text-transform: uppercase;
  }

  @media screen and (max-width: 600px) {
    .event-logo {
      margin: auto !important; 
    }

    .event-table-header {
      font-size: 12px;

    }
    .event-table-body { 
      font-size: 12px;
    }
  }
</style>
