<template>
  <div class="row">
    <div class="cover-image">
      <div class="gradient-overlay"></div>
      <h1 class="cover-title text-center">{{ $t("explore_events" )}}</h1>
      <input type="text" class="form-control filter-events" :placeholder="$t('search')" v-model="search_phrase" >
      <a class="mt-4 text-white d-block text-center" href="mailto:sales@vertical-life.info"><u>Get Scorecard Service</u></a>
    </div>
    <div class="content">
      <div class="container">
        <event-table v-if="local_upcoming" :kind="$t('ongoing')" :events="local_happening"></event-table>
        <event-table v-if="local_happening" :kind="$t('upcoming')" :events="local_upcoming"></event-table>
        <event-table v-if="local_past" :kind="$t('past')" :events="local_past"></event-table>
      </div>
    </div>
  </div>
</template>

<script>
import 'client/images/index_cover'
import EventTable from './event_table'

export default {
  props: ['upcoming_events', 'happening_events', 'past_events', 'translations', 'locale'],
  data: function(){
    return {
      local_upcoming: this.upcoming_events,
      local_happening: this.happening_events,
      local_past: this.past_events,
      search_phrase: ''
    }
  },
  components: {
    'event-table': EventTable
  },
  methods: {
    eventsFilter: function(ev) {
      let matched = false;
      if (ev.name && ev.name.toLowerCase().includes(this.search_phrase.toLowerCase())) {
        matched = true
      }
      if (ev.competition.name && ev.competition.name.toLowerCase().includes(this.search_phrase.toLowerCase())) {
        matched = true
      }
      if (ev.location && ev.location.toLowerCase().includes(this.search_phrase.toLowerCase())) {
        matched = true
      }
      return matched;
    }
  },
  watch: {
    search_phrase: function(val){
      if (val.length > 0) {
        this.local_upcoming = this.upcoming_events.filter(this.eventsFilter);
        this.local_happening = this.happening_events.filter(this.eventsFilter);
        this.local_past = this.past_events.filter(this.eventsFilter);
      } else {
        this.local_upcoming = this.upcoming_events;
        this.local_happening = this.happening_events;
        this.local_past = this.past_events;
      }
    }
  }
}
</script>

<style scoped>
  .content {
    background: rgb(243, 239, 232);
    width: 100%;
    min-height: 50vh;
  }
  .cover-image {
    padding-left: 1rem;
    padding-right: 1rem; 
    width: 100%;
    height: 15rem;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    background: linear-gradient(-134deg, rgba(200,109,215,.75)0%, rgba(48,35,174,.75) 100%), url('../images/index_cover');
  }
  .cover-title {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    color: white;
  }
  
  .filter-events {
    max-width: 400px;
    margin: auto;
    border-radius: 100px;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0 7px 13px 0 rgba(0,0,0,0.30), 0 2px 2px 0 rgba(0,0,0,0.22);
    border-radius: 100px;
    border: 0;
  }
</style>
